import React, { useState, useEffect, useMemo } from 'react'
import { Pagination, Skeleton } from 'antd'
import { arrDate } from '../utils/createTime'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import { getQueryVariable, updateQueryStringParameter } from '../utils/getQueryVariable'

interface MdxEdge {
  node: {
    slug: string
    frontmatter: {
      title: string
      date: string
      language: string
    }
  }
}

function CommunityData(props: any) {
  // const content = allMdx.edges[0].node
  // const { entryType } = props
  const locale = props.locale
  const filteredData = props.filteredData
  const paginationData = props.paginationData
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10

  // 初始化分页状态
  useEffect(() => {
    const queryPage = parseInt(getQueryVariable('page') || '1')
    const safePage = Math.min(Math.max(1, queryPage), paginationData.length)
    setCurrentPage(safePage)
  }, [paginationData.length])

  // 处理分页变化
  const handlePageChange = (page: number) => {
    if (typeof window === 'undefined') return

    // 更新 URL
    const newUrl = updateQueryStringParameter(window.location.href, 'page', page)
    window.history.replaceState({ path: newUrl }, '', newUrl)
    
    // 更新状态
    setCurrentPage(page)
  }

  // 当前页数据
  const currentData = useMemo(() => {
    return paginationData[currentPage - 1] || []
  }, [currentPage, paginationData])

  return (
    <div className={'container community-data'}>
      <ul className="data-list">
        {currentData.length > 0 ? (
          currentData.map((edge: MdxEdge, index: number) => {
            const { frontmatter, slug } = edge.node
            return (
              <li key={`${slug}-${index}`}>
                <Link to={`/${slug}`}>
                  <h3>{arrDate(frontmatter?.date, locale)}</h3>
                  <p>{frontmatter?.title}</p>
                </Link>
              </li>
            )
          })
        ) : (
          Array(3)
            .fill(null)
            .map((_, i) => <Skeleton key={i} paragraph title active />)
        )}
      </ul>
      {paginationData.length > 1 && (
        <Pagination
          current={currentPage}
          total={filteredData.length}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      )}
    </div>
  )
}

export default CommunityData
